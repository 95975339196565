import React, { Component } from 'react';
import Home from 'components';

class HomeContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <Home />;
  }
}

export default HomeContainer;
