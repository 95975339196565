import React, { Fragment } from 'react';
import Bride from 'assets/bride.svg';
import Groom from 'assets/groom.svg';
import Map from 'assets/map.svg';
import Wedding from 'assets/wedding.svg';
import 'styles/styles.scss';

const Home = () => (
  <Fragment>
    <header className="header">
      <div className="container">
        <div className="row text-center">
          <div className="col-10 offset-1 col-md-6 offset-md-3">
            <h1>We're getting married.</h1>
            <h2>Trishna & Debashis</h2>
            <img className="wedding" src={Wedding} alt="" />
          </div>
        </div>
      </div>
    </header>
    <main className="invitation">
      <div className="container">
        <div className="row text-center">
          <div className="col-10 offset-1 col-md-8 offset-md-2">
            <h2>The Invitation</h2>
            <p className="lead">
              Together with their families, Trishna and Debashis cordially
              invite you to celebrate their wedding on Sunday, 27 January 2019.
            </p>
            <a
              className="btn btn-primary shadow"
              href="https://calendar.google.com/calendar/r/eventedit?dates=20190127T043000Z/20190127T153000Z&location=Assam,+India&text=Trishna+%26+Debashis+are+getting+married.&details=Please+celebrate+with+us%0Athe+freshness+of+new+life%0Aand+new+love+as+we,%0A%0A%3Cb%3ETrishna+Barman%3C/b%3E%0Aand%0A%3Cb%3EDebashis+Barman%3C/b%3E%0A%0Aexchange+wedding+vows+on%0A%0A%3Cb%3ESunday,+the+twenty+seventh+of+January,+two+thousand+nineteen%3C/b%3E.%0A%0AEvent+details:+%3Ca+href%3D%22https://www.trishnadebashis.in%22%3Ewww.trishnadebashis.in%3C/a%3E."
              target="_blank"
              rel="noopener noreferrer">
              Add to My Calendar
            </a>
          </div>
        </div>
      </div>
      <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="-300 0 950 270">
        <path
          d="M-314,267 C105,364 400,100 812,279"
          fill="none"
          stroke="white"
          strokeWidth="120"
          strokeLinecap="round"
        />
      </svg>
    </main>
    <section className="bride-groom">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-10 offset-1 col-md-8 offset-md-2 text-center">
            <h2>Bride & Groom</h2>
          </div>
          <div className="col-10 offset-1 col-md-5 offset-md-1">
            <h3>
              Trishna, <small>The Bride</small>
            </h3>
            <p className="lead">
              Pursuing M. Tech. in Information Technology; foodie; loves
              cooking, internet, playing video games; based in Guwahati
            </p>
          </div>
          <div className="col-9 offset-1 col-md-4 offset-md-1 avatar-container">
            <div className="bride-avatar shadow" />
            <img src={Bride} alt="" />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-10 offset-1 col-md-5 offset-md-1 order-md-2">
            <h3>
              Debashis, <small>The Groom</small>
            </h3>
            <p className="lead">
              Software Developer; B. Tech. in Computer Science; loves
              travelling, mountains, internet; currently in Noida
            </p>
          </div>
          <div className="col-9 offset-1 col-md-4 offset-md-1 avatar-container">
            <div className="groom-avatar shadow" />
            <img src={Groom} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="event">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-10 offset-1 col-md-5 offset-md-1 col-lg-4 offset-lg-2">
            <h2>Schedule</h2>
            <p>
              The Wedding ceremony will begin on 25 January 2019 and end on 27
              January 2019.
            </p>
            <small>
              Wedding will be live on{' '}
              <a href="https://www.pscp.tv/DebashisBarman">Periscope</a>.
            </small>
          </div>
          <div className="col-10 offset-1 col-md-5 offset-md-0 col-lg-4 offset-lg-0">
            <ul>
              <li className="shadow">
                <span>
                  <strong>Juron</strong>, 25 January 2019
                </span>
              </li>
              <li className="shadow">
                <span>
                  <strong>Wedding</strong>, 27 January 2019
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 126">
        <path d="M0 0c339.243 157.199 949.61 22.532 1448.36 0v177.143H0z" />
      </svg>
    </section>
    <section className="destination">
      <div className="container">
        <div className="row align-items-center text-center">
          <div className="col-8 offset-2 col-md-2 offset-md-1 order-md-2">
            <img src={Map} alt="" />
          </div>
          <div className="col-10 offset-1 col-md-4 offset-md-0 order-md-1">
            <h3>Bride's Place</h3>
            <address>
              Shiva Durga Mandir, Near Guwahati Blind School
              <br />
              Latakata Basistha, Guwahati, Assam, 781029
            </address>
            <small>
              <a href="https://goo.gl/maps/EzPpTGhEDLo">View on Map</a>
            </small>
          </div>
          <div className="col-10 offset-1 col-md-4 offset-md-1 order-md-3">
            <h3>Groom's Place</h3>
            <address>
              Own Residence, Tihu Town (Bidyanagar)
              <br />
              Ward No. 1, Nalbari, Assam, 781371
            </address>
            <small>
              <a href="https://goo.gl/maps/tZFVDxxsT772">View on Map</a>
            </small>
          </div>
        </div>
      </div>
    </section>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-10 offset-1 col-md-8 offset-md-2 text-center">
            <p className="lead">We hope to see you in January.</p>
          </div>
        </div>
      </div>
    </footer>
  </Fragment>
);

export default Home;
